import logo_up from './img/logo_up.png';
import logo_sku from './img/stand.png';
import rotate from './img/rotate.png';

import './css/App.css';
import React, {useMemo} from "react";
 import * as d3 from "d3";
 import * as Plot from "@observablehq/plot";
// import dataset from './data/top100.tsv';
import world110m from './map_data/world-110m.geo.json';

import { Grid,Drawer,Card, CssBaseline,GeistProvider,AutoComplete,Themes,Slider,Image,Note,Spacer,Modal,Text,Divider,Loading,Link,Tooltip,Input,Button} from '@geist-ui/core'
import Clock from '@geist-ui/icons/clock'
import Map from '@geist-ui/icons/map'
import Calendar from '@geist-ui/icons/calendar'
import RotateCcw from '@geist-ui/icons/rotateCcw'

const myTheme = Themes.createFromLight({
  type: 'coolTheme',
  palette: {
    success: '#1A428A',
  },

})

const test = {
// "border":'1px solid red'
}

function App() {

  const containerRef = React.useRef()
  let [data,setData] = React.useState([])
  let [option,setOptions] = React.useState([])
  const [search, setSearch] = React.useState("")

  const [longitude, setLongitude] = React.useState()
  const [latitude, setLatitude] = React.useState()
  const [sizeMap, setSizeMap] = React.useState(-10)
  const [state, setState] = React.useState(false)
/*  const open = () => {
    setState(true)
  }*/

  const [modal, setModal] = React.useState(false)
  const handler = () => {
      window.open('https://standwithukraine.ukrainianworldcongress.org/uk/')
      return null;
      setModal(true)
  }
  const closeHandler = (event) => {
    setModal(false)
    // console.log('closed')
  }

  let [country,setCountry] = React.useState('')
  let [city,setCity] = React.useState('')
  let [name,setName] = React.useState('')
  let [date,setDate] = React.useState('')
  let [time,setTime] = React.useState('')
  let [location,setLocation] = React.useState('')
  let [select,setSelect] = React.useState('')



    const dataAfterSearch = useMemo(() => {
        if(!search){
            const dL = data.length;
            return data.slice(dL-5,dL);
        }
        let newData =  data.filter((i)=>{
            const val = i.key.toLowerCase();
            const searchLower = search.toLowerCase();
            const res = val.indexOf(searchLower) !== -1;
            return res
        });
        const ndl = newData.length;

        return newData.slice(ndl-5,ndl)

    }, [data,search]);

    React.useEffect(() => {
      d3.csv('https://docs.google.com/spreadsheets/d/e/2PACX-1vQrMscUULymQDz0WVbVKFrgmkVKMfYANYTDBlpZEQ6H0HITshwAVrG4tAv48qhYM1LH73sjwDj-GUBA/pub?output=csv').then(function(data) {

        // console.log(data)
        data.forEach((e, i) => {
          e.key = e['Країна/Country']+' - '+e['Місто/City'];
          e._id = i+1;
        });

        setData(data)

        /*setOptions(d3.rollups(data,v=>v.length,d=>d['Місто/City']).map(d=>{
        return {
        label:d[0],
        value:d[0]
        }}))*/
      }).catch(function(err) {
          throw err;
      })
  }, []);




  React.useEffect(() => {
    let fil = data.filter(d=>new RegExp(search,'i').test(d.key))
    if (fil.length>0){
        //console.log(fil[0],fil[0].lonlat.split(",")[1])
        const [lat, lon] = fil[0].lonlat.split(",");
        setLongitude(lon)
        setLatitude(lat);
        if(search?.length > 2){
            setSizeMap(600)
        }else{
            setSizeMap(-10)
        }

    }
}, [search]);


  React.useEffect(() => {
    if (data === undefined) return;
    let swidth = window.screen.width;

    const plot = Plot.plot({
    style:{fontSize:14},
      width: swidth>650?950:450,
      //width: window.screen.width-400,

      projection: {
        type: select === "" ? "orthographic" : "mercator",
        rotate: select === "" ? [-longitude,  search?.length > 2 ? -latitude : -10] : null,
        domain: select === "" ? null : world110m.features.find((d) => d.properties.name == select),
        inset: select !== "" ? 10 : Number(-sizeMap),
      },
      length: { domain: [0, 41e6], range: [0, 1] },

      marks: [
        Plot.graticule({ strokeOpacity: 0.15 }),

        // LAND
        // () => insetShadow,
        Plot.geo(world110m, {
          fill: "#214c9f",
          opacity: d=>d.properties.name==='Russia'?.4:1,
          // imageFilter: "url(#inset-shadow)"
        }),

        // BORDERS
        Plot.geo(world110m, { stroke: "#333", strokeOpacity: 0.7 }),

        // DOTS
        Plot.dot(data, {
          x: d=>d.lonlat.split(",")[1],
          y: d=>d.lonlat.split(",")[0],
          r: 3,
          fill: "#FFCE06",
          stroke:'#000',
          strokeWidth:.2,
          opacity:d=>new RegExp(search,'i').test(d.key)?1:.2,
          // fillOpacity: 0.8,
          // imageFilter: "drop-shadow(0.07rem 0.07rem 0.07rem white)",
          // tip: true,

        }),

        Plot.tip(data, Plot.pointer(
        {
          x: d=>d.lonlat.split(",")[1],
          y: d=>d.lonlat.split(",")[0],
          filter: d=>new RegExp(search,'i').test(d.key),
          frameAnchor:'top',
          anchor: "top",
          title: (d) =>
          `Країна: ${d['Країна/Country']},
          \nМісто: ${d['Місто/City']},
          \nОрганізатор: ${d['Назва організації/Name of the organisation']},
          \nДата: ${d['Дата /Date']},
          \nЧас: ${d['Місцевий час/ Local time']},
          \nЛокація: ${d['Місце проведення акції/Location']}
          `
          // title: (d) => [d.name, d.info].join("\n\n")
        })),
         // DOTS
        //  Plot.dot(data,
        //    Plot.pointer(
        //    {
        //     x: d=>d.lonlat.split(",")[1],
        //     y: d=>d.lonlat.split(",")[0],
        //     r: 4,
        //     fill: "#FFCE06",
        //     stroke:'#fff',
        //     strokeWidth:1,
        //     }
        //     )
        //   ),

         // DOTS
        //  Plot.dot(data,
        //  {
        //   x: d=>d.lonlat.split(",")[1],
        //   y: d=>d.lonlat.split(",")[0],
        //   r: 8,
        //   fill: "red",
        //   opacity:0
        // }
        // ),


        Plot.sphere({
          stroke: "#eee",
          imageFilter: "blur(5px)",
          clip: "sphere"
        })

        // legendBar([1e6, 1e7, 2e7, 4e7], { fill: "#D80B8C", fillOpacity: 0.8 })
      ],

      // caption:
      //   "Source: GHS Urban Centre Database 2015. European Commission, Joint Research Centre (JRC)"
    })

    // d3.select(plot).append('circle').attr('x','20px').attr('y','20px').attr('r',20)
    // d3.select(plot).selectAll('[fill="orangered"]').attr('fill','blue')

    // d3.select(plot).selectAll('[aria-label="geo"]').selectAll('path')
    // .on("click", function (d, i) {
    //   console.log("d1", i, d, world110m.features[i].properties.name);
    //   setSelect(world110m.features[i].properties.name)
    // });


    // d3.select(plot).selectAll('[fill="red"]').selectAll('circle')
    // .on("click", function (d, i) {
    //   // rects.attr("rx", null).classed("highlight", false);
    //   console.log("d2", i, d, data[i]);
    //   // d3.select(this).attr("opacity", 1).attr("fill", "blue");
    //   open()
    //   setCountry(data[i]['Країна/Country'])
    //   // setCity(data[i]['Місто/City'])
    //   // setName(data[i]['Назва організації/Name of the organisation'])
    //   // setDate(data[i]['Дата /Date'])
    //   // setTime(data[i]['Місцевий час/ Local time'])
    //   // setLocation(data[i]['Місце проведення акції/Location'])
    //   // d3.select(this).raise().attr("rx", 4).classed("highlight", true);
    // });


    containerRef.current.append(plot);
    return () => plot.remove();
  }, [data,longitude,latitude,sizeMap,select,search]);





  //console.log('APP:',search,longitude,latitude,sizeMap)


    const renderCards = useMemo(()=>{
        return dataAfterSearch.map((d,index)=>(
                <div key={d._id+''} index={index}>

                    <Card style={{paddingRight:'30px', color:'#071C3F'}}>
                        <Text style={{fontWeight:700}}>{d['Країна/Country']} ({d['Місто/City']}) </Text>
                        {/* <Spacer h={1}/> */}
                        <Text style={{opacity:.7}} font='12px'>{d['Назва організації/Name of the organisation']}</Text>
                        <Text small><Calendar size={16}/> {d['Дата /Date']}</Text>
                        <Spacer h={1}/>
                        <Text small><Clock size={16}/> {d['Місцевий час/ Local time']}</Text>
                        <Spacer h={1}/>
                        <Text small><Map size={16}/> {d['Місце проведення акції/Location']}</Text>
                        <Spacer h={1}/>
                    </Card>

                    <Spacer h={1}/>
                </div>
            ))
    },[dataAfterSearch])



return (
  <div className="App">


    <div style={{backgroundColor: "#071C3F", color:'red'}}></div>
      <Modal width="60rem" visible={modal} onClose={closeHandler} style={{backgroundColor: "#071C3F", color:'red'}}>
        <Modal.Title>StandWithUkraine</Modal.Title>
        <Spacer h={1}/>
        <Modal.Subtitle>Глобальна кампанія до другої річниці від початку повномасштабного вторгнення</Modal.Subtitle>
        <Modal.Content>
        <Spacer h={1}/>
          {/* <Text>StandWithUkraine: глобальна кампанія до другої річниці від початку повномасштабного вторгнення</Text>  */}

          <Text>Світовий Конґрес Українців започатковує всесвітню кампанію громадських акцій StandWithUkraine до 24 лютого 2024 року –  другої річниці від початку повномасштабного вторгнення. </Text>
          <Text>Основна мета кампанії – привернути увагу світового громадянського суспільства, медіа, світових лідерів, урядів світу до російсько-української війни та посилити підтримку України.</Text>
          <Text>Спільно з Українською правдою ми створили інтерактивну мапу світу StandWithUkraine, на якій будуть позначені країни, міста, локації, дата та час проведення акцій до другої річниці від початку повномасштабного вторгнення.
          </Text>
          <Text>Мапа світу StandWithUkraine вказує, де український голос і вплив  стануть ключовим елементом глобальної підтримки у надскладні часи боротьби українського народу з російським агресором за свою незалежність.
          </Text>
          <Text>Закликаємо українські громади та організації по всьому світу реєструвати свої мирні акції та заходи, які плануються до 24 лютого 2024 року за цим посиланням, аби єдиним голосом нагадати світу про геноцидну війну, яка досі триває.
          </Text>
          <Text>Тож, шукайте на мапі континенти, країни та міста, де проходять акції,  закликайте українців та друзів України по всьому світу долучитися до кампанії.
          Реєструйте захід у своєму місті та станьте частиною світової української громади.
          </Text>
          <Text>StandWithUkraine. Об’єднаймося всім світом у знак солідарності з Україною!
          </Text>
        </Modal.Content>
      </Modal>



    <Spacer h={1}/>

    <Grid.Container  justify="center" alignItems="flex-start"
    // style={{background:'#ece7f2'}}
    >
        {/* <Grid xs={0} sm={0} md={1}  direction="column" style={test} >
        </Grid> */}
        <Grid xs={23} sm={23} md={16}  direction="column" style={test} >
            <Grid.Container style={test} >
                <Grid xs={23} sm={24} md={7} justify="center" direction="column"  style={test}>
                  <Image width="200px"  src={'/logoW.svg'} />
                  <Spacer h={1}/>
                  <Image width="200px"  src={logo_up} />
                </Grid>
                <Grid xs={0} sm={0} md={2} justify="center" direction="column" style={test}>
                </Grid>
                <Grid xs={23} sm={23} md={14} direction="column" style={test}>
                 <Text h2 style={{color:'#fff',fontWeight:400}}>ДОЛУЧАЙТЕСЬ ДО СВІТОВОЇ АКЦІЇ!<br/>Join the Global Rally!</Text>

                 <Grid.Container style={test} >

                 <Grid xs={23} sm={23} md={14} alignItems="center" direction="row" style={test}>

                  <Button ghost auto type="secondary" scale={1} style={{background:'#124299',color:'#fff'}} onClick={handler}>ПРО ПРОЄКТ</Button>
                  <Spacer w={1}/>

                  <Link href="https://docs.google.com/forms/d/1WJELmY1VgCUIpJnVOGzkKmUXXggvx7qO-1RX-TswXNA/viewform?pli=1&pli=1&edit_requested=true" underline>
                  <Button ghost auto type="secondary" scale={1} style={{background:'#FFCE06',color:'#333'}}>ДОДАТИ ПОДІЮ</Button></Link>
                  <Spacer w={1}/>

                </Grid>
                <Spacer h={.5}/>
                <Grid xs={23} sm={23} md={8}  direction="column" style={test}>
                  <Text small className={'standWU'} style={{color:'#fff',fontWeight:400}}>24.02.2024<br/>Stand With Ukraine!</Text>
                  {/* <Text small style={{color:'#fff',fontWeight:400}}>Stand With Ukraine!</Text> */}
                </Grid>

                </Grid.Container>
                </Grid>
                {/* <Grid xs={0} sm={0} md={1} justify="center" direction="column"  style={test}>
                </Grid> */}

                {/* <Grid xs={0} sm={0} md={2} justify="center" direction="column"  style={test}>
                </Grid> */}
              </Grid.Container>

              <Spacer h={3}/>

              {/* <Divider /> */}

            <Grid.Container justify="center">
                <Grid xs={23} sm={12} md={6} justify="center" style={test}>


                  {/* <GeistProvider themes={[myTheme]} themeType="coolTheme">
                  <CssBaseline />
                  <Slider step={1} max={180} min={-180} initialValue={10} width="100%" onChange={(val)=>setLongitude(val)}/>
                  </GeistProvider> */}
                  <Input width='100%' placeholder="місто або країна / city or country"
                  // options={option}
                  style={{color:"#fff"}}
                  onChange={(e)=>setSearch(e.target.value)}/>

                  </Grid>
                  <Spacer w={1}/>
                  <Grid xs={23} sm={12} md={6} justify="center" style={test}>
                   <Image src={'/i360.svg'} style={{width: '90%', position: 'relative', top: -2}} />
                  {/* <Spacer w={.5}/> */}
                    <div className="slidecontainer">

                    <input
                    className={`slider`} id="myRange"
                    type="range"
                    min="-180" max="180"
                    value={longitude}
                    onChange={(val)=>setLongitude(val.target.value)}
                    step="1"/>
                    </div>

                  {/* <RotateCcw color="#fff"/> */}
                  {/* <Slider step={1} max={180} min={-180} initialValue={10} width="100%" onChange={(val)=>setLongitude(val)}/> */}
                </Grid>
                <Spacer w={1}/>
                <Grid xs={23} sm={12} md={6} justify="center" style={test}>
                    <span className={`textZoom ${search.length < 3 ? 'sliderDisable':''}`}>-</span>
                    {/* <Spacer w={.5}/> */}
                    <div className="slidecontainer">

                        <input
                            className={`slider ${search.length < 3 ? 'sliderDisable':''}`} id="myRange2"
                            type="range"
                            min="-10" max="2000"
                            value={sizeMap}
                            onChange={(val)=>setSizeMap(val.target.value)}
                            disabled={search.length < 3}
                            step="10"/>
                    </div>
                    <span className={`textZoom ${search.length < 3 ? 'sliderDisable':''}`}>+</span>
                    {/* <RotateCcw color="#fff"/> */}
                    {/* <Slider step={1} max={180} min={-180} initialValue={10} width="100%" onChange={(val)=>setLongitude(val)}/> */}
                </Grid>

            </Grid.Container>

            <Spacer h={1}/>

            <div ref={containerRef} style={test}/>

        </Grid>
        <Grid xs={24} sm={20} md={7} justify="center" direction="column">
          <Grid.Container style={{padding:'10px'}} >
          <div className="sample-item">
            <div className="dot dot--basic" aria-hidden="true"></div>
            {/* <strong>Live</strong> */}
          </div>
          <Text small style={{color:'#fff',fontWeight:800,padding:'5px'}}>Останні додані події:</Text>
          <Grid direction="column"  xs={22} sm={22} md={22} style={{color:'#1A428A'}}>

          {renderCards}

          </Grid>
          </Grid.Container>
       </Grid>
        {/* <Grid xs={24} sm={20} md={1} justify="center" direction="column" style={test} >
        </Grid> */}
    </Grid.Container>











      {/* <Button auto onClick={() => open()} scale={1/2}>Left</Button> */}
      <Drawer style={{maxWidth:'300px'}} visible={state} onClose={() => setState(false)} placement='left'>
        <Drawer.Title>{country}</Drawer.Title>
        <Spacer h={1}/>
        {/* <Text small><Calendar size={16}/>{date} </Text>
        <Text small><Clock size={16}/> ({time})</Text>
        <Text small><Map size={16}/>{location}</Text>
        <Divider />
        <Drawer.Subtitle>{city}</Drawer.Subtitle> */}
      </Drawer>





  </div>
  )
}



export default App;
